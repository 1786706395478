import React, { useState } from "react";
import {
  FaCheck,
  FaCircleCheck,
  FaAngleUp,
  FaAnglesDown,
  FaAngleDown,
} from "react-icons/fa6";
import { MdSwapVert } from "react-icons/md";
const Swap = ({ balance, invitedPepole }) => {
  const [coin, setCoin] = useState(0);
  return (
    <div className="p-3">
      <div
        className="flex justify-between items-center text-stone-100 p-2 border-gray-200 border-opacity-40 border-b-[1px]"
        style={{
          fontFamily: "Times New Roman, Times, serif",
          fontWeight: "bold",
        }}
      >
        <div>1M Notcoin</div>
        <FaCircleCheck size={22} color={balance > 1000000 ? "green" : "gray"} />
      </div>
      <div className="flex justify-between items-center text-stone-100 p-2 border-gray-200 border-opacity-40 border-b-[1px]"
        style={{
          fontFamily: "Times New Roman, Times, serif",
          fontWeight: "bold",
        }}>
        <div>5 Invitations</div>
        <FaCircleCheck
          size={22}
          color={invitedPepole >= 5 ? "green" : "gray"}
        />
      </div>
      <p
        className="text-justify text-white p-2"
        style={{
          fontFamily: "Times New Roman, Times, serif",
          fontWeight: "bold",
        }}
      >
        To initiate the swap process, ensure you meet the minimum requirements:
        maintain a balance of at least 1 million NotCoins in your wallet and
        have successfully invited a minimum of 5 friends using your unique
        referral link. Once these criteria are met, simply click on the "Swap"
        button below to begin the exchange. Upon confirming the transaction
        details, your NotCoins will be swiftly converted into USDT.
      </p>
      <form class="max-w-md mx-auto">
        <div class="relative my-2 w-fit mx-auto">
          <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
            <img src="/coin.png" className="w-[35px] h-[35px]" alt="" />
          </div>
          <input
            type="number"
            id="default-search"
            class="block w-full p-4 ps-14 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:outline-none focus:outline-none "
            placeholder="Amount"
            onChange={(e) => setCoin(e.target.value)}
          />
        </div>
        <div className="w-fit mx-auto flex gap-2">
          <MdSwapVert size={30} />
        </div>
        <div class="relative my-2 w-fit mx-auto">
          <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
            <img
              src="https://cryptologos.cc/logos/tether-usdt-logo.png"
              className="w-[35px] h-[35px]"
              alt=""
            />
          </div>
          <input
            type="search"
            id="default-search"
            class="block w-full p-4 ps-14 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:outline-none focus:outline-none "
            placeholder="USDT Amount"
            disabled
            value={coin / 8736}
          />
        </div>
        <div className="flex justify-center pt-2">
          <button
            className={`${
              balance > 1000000 && invitedPepole >= 5
                ? "bg-green-500"
                : "bg-gray-400"
            }  rounded-lg px-3 py-2 mx-auto w-fit`}
            type="submit"
            disabled={balance < 1000000 || invitedPepole < 5}
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default Swap;
