import axios from "axios";
import React, { useState } from "react";
import { BASE_URL } from "../config";

const Earn = ({ level, address, balance, setlevel, setCoin }) => {
  const [newlevel, setNewlevel] = useState();
  async function handleLevelUp() {
    if (level >= 10) {
      return alert("Your level is max");
    }
    if (balance >= level * 100000) {
      await axios
        .post(BASE_URL + "/user/levelup", {
          address: address,
        })
        .then(({ data }) => {
          setCoin(balance - (newlevel ? newlevel : level) * 100000);
          setNewlevel(data?.data?.level);
          setlevel(data?.data?.level);
          alert("Level Up Successfull");
        });
    } else {
      alert("You Need More Tokens");
    }
  }

  return (
    <div className="text-stone-100 px-5 pb-5 pt-3 text-justify">
     <div className="flex flex-col justify-center items-center border-gray-200 border-opacity-40 pb-2 border-b-[1px] mb-4 text-orange-600">
        <div>USDT Balance:</div>
        <div className="text-[40px]">{balance / 1000} USDT</div>
      </div>
      <p className="border-b border-[#ffffff50] pb-2">
        You will receive 100,000 tokens for each user you invite
      </p>
      <p className="text-center mt-3">
        You need {newlevel ? newlevel * 100000 :  level * 100000} token to level
        up
      </p>
      <div className="flex justify-between items-center ">
        <span>Level: {newlevel ? newlevel : level}</span>
        <span>
          <button
            onClick={handleLevelUp}
            className="bg-orange-500 rounded-lg px-3 py-1 m-2"
          >
            Level Up
          </button>
        </span>
      </div>
    </div>
  );
};

export default Earn;
