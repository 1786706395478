import React from "react";
import { useState } from "react";

const Team = ({ setShow, selected, setselected }) => {
  const teams = [
    {
      id: 1,
      name: "Hostert  ",
      score: "4,950,000",
      members: ["Alex", "Sara", "Ben"],
      logo: "https://logowik.com/content/uploads/images/fc-us-hostert-niederanven1447.jpg",
    },
    {
      id: 2,
      name: "Nexen",
      score: "4,550,000",
      members: ["Reza", "Hosein"],
      logo: "https://logowik.com/content/uploads/images/nexen-heroes4112.jpg",
    },
    {
      id: 3,
      name: "Dice",
      score: "4,154,000",
      members: ["Benjamin", "Ube"],
      logo: "https://logowik.com/content/uploads/images/t_dice9231.jpg",
    },
    {
      id: 4,
      name: "ISC 2",
      score: "3,840,241",
      members: ["Jhon Doe"],
      logo: "https://logowik.com/content/uploads/images/isc21598.logowik.com.webp",
    },
    {
      id: 5,
      name: "Notion",
      score: "3,421,200",
      members: ["Doe", "Nadia", "David"],
      logo: "https://logowik.com/content/uploads/images/notion1411.jpg",
    },
    {
      id: 6,
      name: "Grindr",
      score: "3,111,100",
      members: ["Dark Kinight", "Joji", "Lizard"],
      logo: "https://logowik.com/content/uploads/images/grindr5618.jpg",
    },
    {
      id: 7,
      name: "Square",
      score: "2,500,200",
      members: ["Savior", "Lamar"],
      logo: "https://images.squarespace-cdn.com/content/v1/570d66d8b09f951fdd61a339/a3b2e2e2-6bf7-4f1f-aa78-c2d9bc47764c/All_v14.jpg?format=750w",
    },
    {
      id: 8,
      name: "M Theory",
      score: "2,188,000",
      members: ["Akon", "Scott"],
      logo: "https://images.squarespace-cdn.com/content/v1/570d66d8b09f951fdd61a339/04939441-0737-4fec-93d8-8aaae6517e95/All_v15.jpg?format=750w",
    },
  ];
  return (
    <>
      <div class="relative overflow-x-auto shadow-md  mt-">
        <table class="w-full text-sm text-left rtl:text-right ">
          <thead class="text-xs text-gray-700 uppercase bg-gray-50/75 ">
            <tr>
              <th scope="col" class="px-6 py-3">
                Rank - Name
              </th>

              <th scope="col" class="px-6 py-3">
                Balance
              </th>
            </tr>
          </thead>
          <tbody>
            {teams.map((team) => {
              return (
                <tr
                  onClick={() => {
                    setShow(true);
                    setselected(team);
                  }}
                  class="border-b "
                >
                  <th
                    scope="row"
                    class="flex items-center px-6 py-4 text-white whitespace-nowrap "
                  >
                    <div class="pe-3">
                      <div class="text-base font-[600]">{team.id} -</div>
                    </div>
                    <img
                      class="w-10 h-10 rounded-full"
                      src={team.logo}
                      alt="Jese"
                    />
                    <div class="ps-3">
                      <div class="text-base font-[600]">{team.name}</div>
                    </div>
                  </th>
                  <td class="px-6 py-4 text-white">{team.score}</td>

                  {/* <td class="px-6 py-4">
                <a
                  href="#"
                  class="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                >
                  Edit user
                </a>
              </td> */}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Team;
