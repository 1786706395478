import React from "react";

const Modal = ({ show, onClose, selected }) => {
  if (!show) {
    return null;
  }
  return (
    <div
      onClick={onClose}
      className="flex justify-center items-center fixed top-0 bottom-0 left-0 right-0 bg-[#00000090]  "
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className="w-[500px] bg-white p-3 rounded-lg m-3"
      >
        <div className="flex justify-between items-center ">
          <div>TeamName:</div>
          <div>{selected?.name}</div>
        </div>
        <div className="flex justify-between items-center border-b-[1px] border-gray-500 pb-2">
          <div>Balance:</div>
          <div>{selected?.score}</div>
        </div>
        <div className="mt-2">Members:</div>
        <ul className=" m-3">
          {selected.members.map((member) => (
            <li className="border-b-[1px] border-gray-300">{member}</li>
          ))}
        </ul>
        <button
          className="border-[1px] border-gray-500 rounded-lg px-2 py-1 m-auto flex justify-center items-center"
          onClick={onClose}
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default Modal;
