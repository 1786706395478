import React from "react";
import copy from "clipboard-copy";
import { useState } from "react";
const Invite = ({ text, invitedPepole }) => {
  const [copied, setCopied] = useState(false);
  function handleCopy() {
    copy(text);
    setCopied(true);
  }
  return (
    <div>
      <div className="flex justify-between p-3 items-center border-gray-200 border-opacity-40 border-b-[1px] m-3 text-stone-100">
        <div>Total Invited :</div>
        <div>{invitedPepole}</div>
      </div>
      <div className="flex flex-col justify-center items-center text-stone-900 ">
        <div className="text-[12px] bg-slate-100 mx-4 py-2 rounded-md text-center px-4">
          {text}
        </div>
        <button
          onClick={handleCopy}
          className="bg-orange-500 rounded-lg px-3 py-1 m-2"
        >
          {copied ? "Copied" : "Copy invite link"}
        </button>
      </div>
    </div>
  );
};

export default Invite;
